module services {
    export module tariff {
        export class customsWarehouseService implements interfaces.tariff.ICustomsWarehouseService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getForDropdown(countryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CustomsWarehouse/GetForDropdown", {
                    countryId: countryId
                });
            }
        }
    }
    angular.module("app").service("customsWarehouseService", services.tariff.customsWarehouseService);
}